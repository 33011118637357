.Shop {
  padding-top: 105px;
  padding-bottom: 5%;
}

.Shop .Header {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  /* justify-content: center; */
  padding-left: 40%;
}

.Shop body {
  font-family: Arial, Helvetica, sans-serif;
  color:rgb(0, 31, 97);
}
.Shop h1, h2, h3, p { 
  margin: 0.5rem 0;
  color:rgb(0, 31, 97); 
}
.Shop h1{
  font-size: 2rem;
}
.Shop h2{
  font-size: 1.5rem;
}
.Shop h3{
  font-size: 1rem;
}
.Shop h5{
  font-size: 0.8rem;
}
.Shop .merch{
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-left: 15%;

}
.Shop a {
  text-decoration: none;
  color:rgb(0, 31, 97);
}
.Shop .hr{
  border: 1px solid #000000;
  max-width: 85%;
  margin-left: 8%;
  border-radius: 5px;
  align-content: center;
}
.Shop #header {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
.Shop .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Shop .blockCol1{
  flex: 1;
  padding-left: 5%;
  padding-right: 5%;
  position: fixed;
  width: 23%;
  right: 0;
  color: rgb(0, 31, 97);
}
.Shop .blockCol2{
  flex: 2;
  padding-left: 5%;
  max-width: 64%;
  left: 0;
  color: rgb(0, 31, 97);
}
.Shop .block {
  border: 2px solid black;
  background-color:#1B1C1E;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
}
.Shop .merchCard{
  /* border: 2px solid black; */
  width: 30%;
  padding: 1%;
  /* margin-top: 1%; */
}
.Shop .row-center {
  align-items: center;
}
.Shop img.small {
  max-width: 100%;
  margin-top: 2rem;
}
.Shop button {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  height: 2rem;
  border: 0.1rem #404040 solid;
  background-color: rgb(0, 31, 97);
  color: #ffffff;
  width: 80%;
  justify-items: center;
  cursor: pointer;
}
.Shop button.add {
  background-color: rgb(0, 31, 97);
  width: 2rem; 
}
.Shop button.remove {
  background-color: #6e0101;
  width: 2rem; 
}
.Shop .badge {
  background-color: #003548;
  border: none;
  color: rgb(0, 31, 97);
  width: 2rem;
}
.Shop .textRight {
  text-align: right;
  margin-left: 2%;
  color: rgb(0, 31, 97);
}
.Shop body {
  background-color: #1B1C1E;
}

.Shop .checkout {
  width: 35%;
}