@import url('https://fonts.googleapis.com/css2?family=Allerta&family=Emilys+Candy&family=Grape+Nuts&family=Montserrat:wght@400;500;600;700&family=Nunito+Sans:wght@400;600;700&family=Orbitron:wght@500;700&family=Permanent+Marker&family=Prompt:ital,wght@0,300;1,500;1,700;1,900&family=Raleway:wght@400;500;600&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  font-family: "Allerta";
}

.NavBar {
  /* background-image: linear-gradient(#fedec7, #fedec7, #fedec7, #fedec7, #fedec7, #fedec700); */
  background-image: linear-gradient(#fffeac, #fffeac, #fffeac, #fffeac, #fffeac, #fffeac00);
  height: 100px;
  width: 100vw;
  /* margin-left: 5%; */
  position: fixed;
  z-index: 999;
  /* opacity: 0.9; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavBar .Logo {
  position: absolute;
  /* left: 5%; */
  left: 90px;
  float:left;
  padding-right:1%;
  margin-top:10px;
  cursor: pointer;
}

.NavBar .shine {
  background: linear-gradient(90deg, #fffeac, #fedec7, #89befb, #022e61, #022e61, #89befb, #fedec7, #fffeac);
  /* letter-spacing: 4px; */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  /* background-repeat: repeat; */
  background-size: 80%;
  animation: shine 9s linear infinite;
  position: relative;
}
@keyframes shine {
  0% {
    background-position-x: -500%;
  }
  100% {
    background-position-x: 500%;
  }
}

.NavBar nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.NavBar nav ul li {
  display: inline-block;
  margin: 15px;
}

.NavBar nav ul li a {
  text-decoration: none;
  color: rgb(0, 31, 97);
  /* font-weight: 900; */
  font-size: 1rem;
}

.NavBar a:hover {
  /* border-bottom: 5px solid #c5d248; */
  color: #89befb;
}

.NavBar nav ul li a:active {
  font-weight: bold;
}

.NavBar a:visited {
  font-weight: bold;
}

.NavBar .MobileNavigation {
  display: none;
}

.Footer {
  display: none;
}

@media (max-width: 1100px) {
    .NavBar .Navigation {
      display: none;
    }

    .NavBar {
      background-image: none;
    }

    .NavBar .MobileNavigation {
      display: flex;
      align-items: center;
    }

    .Logo {
      margin: 45%;
      position: absolute;
      /* left: 5%; */
      left: 10px;
      float:left;
      padding-right:1%;
      /* margin-top:10px; */
      cursor: pointer;
    }


    .NavBar nav ul {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      padding-top: 25%;
      padding-bottom: 35%;
      right: 0;
      max-height: 100vh;
      width: 100%;
      z-index: 1;

      /* background: linear-gradient(180deg, #fedec7, #fedec7, #89befb, #022e61); */
      background-image: linear-gradient(#fffeac, #fef4b5, #fedec7,#fedec7, #7dace4, #022e61);
      /* border-top: 2px solid #c5d248; */
    }

    .NavBar nav ul li {
      padding-left: 10%;
      padding-bottom: 5%;
    }

    .NavBar {
      opacity: 1;
    }

    .NavBar a:hover {
      border-bottom: none;
      font-weight: bold;
    }

}

.NavBar .MobileNavigation .Hamburger {
  position: absolute;
  right: 5%;
  cursor: pointer;
  z-index: 2;
}




@media (max-width: 600px) {

  .NavBar .Logo {
    position: absolute;
    /* left: 5%; */
    left: 5px;
    float:left;
    padding-right:1%;
    margin-top:10px;
    cursor: pointer;
  }
}