body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-image: linear-gradient(#fffeac, #fef4b5, #fedec7, #7dace4, #022e61);
  color: white;
}

html {
  height: 100%;
  max-width: 100%;
}
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  max-width: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html, body {
  /* max-width: 100%; */
  overflow-x: hidden;
}

.main {
  flex-grow: 1;
}

.swiper-button-next, .swiper-button-prev {
  color: #043265 !important;
  top: 40% !important;
 }

 .swiper-pagination-bullet-active {
  color:  #043265 !important;
 }