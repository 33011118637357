@import url('https://fonts.googleapis.com/css2?family=Allerta&family=Emilys+Candy&family=Grape+Nuts&family=Montserrat:wght@400;500;600;700&family=Nunito+Sans:wght@400;600;700&family=Orbitron:wght@500;700&family=Permanent+Marker&family=Prompt:ital,wght@0,300;1,500;1,700;1,900&family=Raleway:wght@400;500;600&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.About {
  padding-top: 105px;
  padding-bottom: 1%;
  width: 100vw;
}

body {
  height: 100vh;
}

body::-webkit-scrollbar{
  display: none;
}

.About .Container {
  display: block;
  flex-wrap: wrap;
  box-shadow: 0 0 12px 4px #83b8f4db;
  color: rgb(0, 31, 97);
  padding: 1.5em;
  margin-left: 13%;
  margin-right: 13%;
  line-height: 1.75em;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid #5185978c;
}

.About .Container .Text {
  text-indent: 3%;
  padding-right: 5%;
}

.About .blockColLeft {
  flex: 2;
  padding-left: 5%;
  left: 0;
}

.About .blockColRight{
  flex: 1;
  padding-left: 7%;
  padding-right: 10%;
  width: 40%;
  right: 0;
}

.About p {
  font-size: 1.2rem;
}

.About .blockColRight img{
  width: 80%;
}

.About .Logos {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.About .Logos img {
  width: 35px;
  padding: 2%;
  opacity: 50%;
}

.About .Text a {
  text-decoration: none;
  color: rgb(0, 31, 97);
}

.About .Text a:hover  {
  animation: blinker 0.3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}


@media (max-width: 1100px) {
  

  
  .About .Container {
    display: block;
    flex-wrap: wrap;
    box-shadow: 0 0 12px 4px #83b8f4db;
    color: rgb(0, 31, 97);
    padding: 1.5em;
    margin-left: 5%;
    margin-right: 5%;
    line-height: 1.75em;
    font-size: .9em;
    font-weight: 500;
    border: 1px solid #5185978c;
  }
}




@media (max-width: 600px) {

}