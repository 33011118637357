.Artist {
  padding-top: 105px;
  /* padding-bottom: 1%; */
  width: 100vw;
}

.Artist .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: rgb(0, 31, 97);
}


.Artist .Container {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* width: 80%; */
}

.Artist .Column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.Artist .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  /* padding-left: 5%;
  padding-right: 5%; */
}

.Artist .CardFront {
  background-image: url('./../img/artists/catPeopleTarotBorder-25p.png');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  border: 1px solid #add8e6;
  border-radius: 17px;
  box-shadow: 0 0 12px 4px #83b8f4c0;
  color: whitesmoke;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
  height: 400px;
  width: 400px;
  padding: 10%;
  margin: 15%;
  cursor: pointer;
}

.Artist .CardBack {
  background-image: url('./../img/artists/catPeopleTarot-back75.png');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  border: 1px solid #add8e6;
  border-radius: 15px;
  box-shadow: 0 0 12px 4px #83b8f4c0;
  color: #092765;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
  height: 400px;
  width: 400px;
  padding: 10%;
  margin: 15%;
}

.Artist .CardFront:hover {
  box-shadow: 0 0 12px 4px rgb(0, 31, 97);
  cursor: pointer;
}

.Artist .CardBack:hover {
  box-shadow: 0 0 12px 4px rgb(0, 31, 97);
  cursor: pointer;
}

.Artist .CardFront .Text {
  padding-top: 50%;
  padding-bottom: 15px;
  color: white;
  text-shadow: 5px 5px 5px rgb(0, 31, 97);
}

.Artist .CardFront .Text h4 {
  padding-top: 25px;
  padding-bottom: -5px;
}

.Artist .CardBack .Text h1, h2, h3, p {
  color:#092765;
}

.Artist .CardBack .Text {
  margin-top: 2%;
  padding-top: 5%;
  max-height: 100%;
  padding-bottom: 10px;
  color: #092765;
}

.Artist .CardBack .Text h3 {
  padding-bottom: 3%;
}

/* .Artist .CardBack .Text .IconRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */
.Artist .CardBack .Text .Icon {
  padding-top: 5%;
  /* bottom: 0; */
  /* position: relative; */
}


@media (max-width: 1100px) {
  .Artist {
    padding-top: 80px;
  }
}