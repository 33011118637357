.Home {
  display: flex;
  max-width: 600px;
  /* max-width: 100%; */
  height: 100%;
  margin: auto;
  padding-top: 250px;
  padding-bottom: 150px;
}

body {
  background-color: #191d1f00;
  /* height: 100vh; */
}

@media (max-width: 1100px) {
  .Home {
    padding-top: 180px;
    padding-bottom: 200px;
    /* padding: auto; */
  }
}