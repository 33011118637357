.Book {
  padding-top: 135px;
}

.Books {
  padding-top: 105px;
  padding-bottom: 1%;
  color: black;
}

.Book .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1%;
}

.BooksIndex .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2%;
}

.Book .Container {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BooksIndex .Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BooksIndex .Column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.BooksIndex .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.BooksIndex h1 h2 h3 h4 h5 p {
  color: black;
}

.BooksIndex .Container .Row .BookCover {
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  border: 0.5px solid #518597;
  box-shadow: 0 0 12px 4px #83b8f4c0;
  color: black;
  text-align: center;
  font-size: 16px;
  margin: 20px 20px;
  transition: 0.3s;
  max-width: 325px;
}

.BooksIndex .Books {
  max-width: 300px;
  margin-right: 5%;

}

.BooksIndex .Zine1 {
  max-width: 300px;
  margin-right: 5%;

}

.Book .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1%;
}

.Book .Container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.Book img {
  width: 450px;
}

.Book .Container .Text {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 3%; */
}

.BookText p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  color: #fcddc8;
  width: 60%;
  padding-left: 20%;
}