/* s Index Page CSS */

.Trips {
  padding-top: 105px;
  padding-bottom: 3%;
}

.Trips .HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TripsIndex .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2%;
}

.Trips .Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Trips .Column {
  display: flex;
  flex-wrap: wrap;
}

.Trips .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Trips .Container img {
  max-width: 100%;
  box-shadow: 0 0 12px 4px #5185978c;
}

.TripsIndex .Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TripsIndex .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.TripsIndex .Column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.Trips .Container .Text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TripsIndex a {
  text-decoration: none;
}


.TripsIndex .Container .Row .TripsCover {
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  box-shadow: 0 0 12px 4px #c5cee0;
  text-align: center;
  margin: 15px;
  transition: 0.3s;
  max-width: 700px;
}


.TripsIndex .TripsCover {
  margin: 5%;
}


/* Single Trip Page */

.Trip {
  padding-top: 105px;
  padding-bottom: 2%;
}


.Trip .HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Trip .mySwiper {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Trip .mySwiper .swiperSlide img {
  max-width: 60%;
  padding-left: 20%;
  padding-top: 1%;
  object-fit: cover;
  padding-bottom: 1%;
}

.Trip .Swiper .Nav {
  margin: 15%;
}

.Trips .Container .Text {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 2%; */
}

.TripsText p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
}

.Trip .swiperSlide p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  padding-top: 1%;
  padding-bottom: 3%;
}



@media (max-width: 1100px) {
  
  .Trips {
    padding-top: 80px;
  }
  
  .TripsIndex .Container .Row .TripsCover {
    margin-left: 5%;
    max-width: 90%;
  }
}