/* .Container {
  position: relative;
  height: 2.5rem;
} */


.Footer {   
/* background-image: linear-gradient(#191d1f, black);  */
bottom: 0;
left: 0;
right: 0;
height: 80px;
width: 100vw;
padding-top: 40px;
position: relative;
margin-bottom: 2%;

}

/* .Footer .Row {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
} */

.Footer .FooterIcons {
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: row;
  text-align: center;
  width: 100%;
  /* margin-left: 45%; */
}

/* .Footer .FooterIcons .Icon {
  margin: 0 5%;
} */

.Footer .FooterText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.Footer .FooterText p {
  font-size: 1rem;
}

.Footer .FooterIcons a:hover {
  color: #ffee10;
  box-shadow: 0 0 5px #ffee10;
  text-shadow: 0 0 5px #ffee10;
}

.Footer .FooterIcons svg path {
  cursor: pointer;
  box-shadow: 0 0 12px 4px #5185978c;
}

