/* Exhibitions Index Page CSS */

.Exhibitions {
  padding-top: 105px;
  padding-bottom: 3%;
}

.Exhibitions .HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExhibitionsIndex .Header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2%;
}

.Exhibitions .Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Exhibitions .Column {
  display: flex;
  flex-wrap: wrap;
}

.Exhibitions .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Exhibitions .Container img {
  max-width: 100%;
  box-shadow: 0 0 12px 4px #5185978c;
}

.ExhibitionsIndex .Container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

.ExhibitionsIndex .Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.ExhibitionsIndex .Column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Exhibitions .Container .Text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExhibitionsIndex a {
  text-decoration: none;
}

.ExhibitionsIndex .Container .Row .ExhibitionsCover {
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  box-shadow: 0 0 12px 4px #c5cee0;
  text-align: center;
  margin: 15px;
  transition: 0.3s;
  max-width: 700px;
}

.ExhibitionsIndex .ExhibitionsCover {
  margin: 5%;
}




/* Single Exhibition Page */

.Exhibition {
  padding-top: 105px;
  padding-bottom: 2%;
}

.Exhibition .HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Exhibition .mySwiper {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Exhibition .mySwiper .swiperSlide img {
  width: 90%;
  padding: 1% 5%;
  object-fit: cover;
  padding-bottom: 1%;
}

.Exhibition .Swiper .Nav {
  margin: 15%;
}

.Exhibition .Container .Text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2%;
  width: 50%;
}

.ExhibitionText p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;

}

.Exhibition .swiperSlide p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: light;
  padding-top: 1%;
  padding-bottom: 3%;
  /* width: 75%; */
  /* padding-left: 12.5%; */
  /* color: #fcddc8; */
}


@media (max-width: 1100px) {
  .Exhibitions {
    padding-top: 80px;
  }
  
  .ExhibitionsIndex .Container .Row .ExhibitionsCover {
    margin: 2.5% !important;
    max-width: 95%;
  }

  .Exhibition .mySwiper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .Exhibition .mySwiper .swiperSlide img {
    width: 100%;
    padding-left: 0% !important;
    padding-top: 1%;
    object-fit: cover;
    padding-bottom: 1%;
  }
  
  .Exhibition .Swiper .Nav {
    margin: 15%;
  }
  
  .Exhibition .Container .Text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2%;
    width: 50%;
  }
  
  .ExhibitionText p {
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: light;
    width: 90%;
    padding: 0 0 5% 5%;
  }
  
  .Exhibition .swiperSlide p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: light;
    padding-top: 1%;
    padding-bottom: 5%;
    width: 90%;
    padding-left: 5%;
    /* color: #fcddc8; */
  }

  

}